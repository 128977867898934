
.header-like {
    top: 0px;
    background: linear-gradient(to right, #db2424,#9e1800,#004ddd, #0066ff);
    padding: 0px;
    border-radius: 10px;
    height: 35vh;

}

.portfolio-header {
    z-index: 10;
    vertical-align: top;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: white;
    padding-top:1%;
    padding-bottom:1%;
    margin:0px;
    border-radius: 10px;
    position: fixed;
    top:0;
    width: 100%;

    background: linear-gradient(to right, #db2424,#9e1800,#004ddd, #0066ff);
}

.portfolio-header a {
    color: #ffffff;
    text-decoration: none;
    margin-right: 20px;
    transition: color 0.3s ease;
}

.portfolio-header a:hover {
    color: #007bff;
}