/* Portfolio.css */

.portfolio {
    margin: 0 auto;
    padding: 0px 20px;
}


.overlay-content {
    position: absolute;
    z-index: 2;
    width: 50%;
    height: auto;
    max-height: 50vh;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    top: 15%;
    display: flex;
    flex-direction: row;
    background-color: #ffffff;
    border-radius: 6px;
    overflow: auto;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
}

.icon {
    margin: 10px 10px 10px 10px;
}

.icons {
    position: relative;
    bottom: auto; /* Position the icons at the bottom */
    width: 100%;
    display: flex;
    justify-content: center;
}

.projects {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 40px;
}

.profile-photo {

    
    flex: 1;
    flex-basis: 40%;
    float: left;
    display: flex;
    justify-content: left;
    align-items: center;
}

.profile-photo img {

    
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profile-data {
    flex: 1;
    flex-basis: 60%;
    float: right;
    justify-content: center;
    align-items: center;
    transform: translateX(-15%);
    margin-left:15%;
    margin-right: 0%;
    margin-top: auto;
    margin-bottom: auto;
}
.profile-data p{

    justify-content: left;
    text-align: left;
}
.hello {
    font-size: 3.5vh;
}


.profile-data span {
    white-space: pre-line;
}


.portfolio-section {
    margin-top: 2%;
    margin-bottom: 40px;
    width: 100%;
}

.portfolio-section h2 {
    font-size: 28px;
    text-align: center;
    margin-bottom: 20px;
    position: relative;
}

.portfolio-section h2::after {
    content: "";
    display: block;
    width: 60px;
    height: 2px;
    background-color: #007bff;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
}

.experiences{
    position: absolute;
    top: 65vh;
    left:50%;
    transform: translateX(-50%);
    width: 100%;
}

.icons{
    margin-top: 50px !important;
}
    
.icons .social-icon{
    height: auto;

}
.icons .social-svg{
    height: auto !important;
    width: auto !important;
}

.icons .social-container{

    top: -5px !important;
}

.help-btn{
    position: fixed;
    top: 85% !important;
    left: 85%;
    z-index: 998;
    font-size: 18px !important;
    height: 60px !important;
    width: 180px !important;
}



.question-photo {
    margin-top:0.8% !important;
    margin-left: 1.5% !important;
    height: 4% !important;
    width: 4% !important;
    max-height: 4% !important;
    max-width: 4% !important;
    margin: 0px 0px 0px 0px ;
    padding: 0px 0px 0px 0px ;
    cursor: pointer;
}

.question-photo img {

    
    width: 100%;
    height: 100%;
}
.experience .description{
    font-weight: normal;
}
.experience {
    max-height: 1% !important;
    height: 1% !important;
}
.experience p {
    color: grey !important;
    align-items: top;
    margin-top: -0.5% !important;
    margin-left:30%;
}

.experience h3 {
    margin-bottom: 0px;
    align-items: center;
    margin-left: 30%;
    display: inline-flex;
}



.collapse-btn{
    z-index: 7;
    margin-top: 4% !important;
}

.expand-btn {
    margin-left: 10px;
}