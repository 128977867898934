
.collapse-btn {
    position: absolute;
    margin-left: 90%;
    margin-top:2%
}

.prof-experience-box{

    max-height: 50em !important;
    height: 50em !important;

    top:5%;
    z-index: 10;
    border-radius: 6px;
    box-shadow: 15px 15px 15px 15px rgba(0, 0, 0, 0.295);
    align-items: center;
    border-radius: 10% !important;
    width: 80%;
    margin-left:10% !important;
}
.professional-exp-details {

    overflow: hidden;
    top: 0%;
    left: 10%;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: row;
}
.exp-title{
    position: relative;
    display: grid;
    width: 100%;
    text-align:left;

}
.whitespace{
    color: transparent;
}
.exp-title  h3{
    margin-top:3% !important;
    display: inline-block;
    width: 100%;
    align-items: center !important;
    font-size: 4vh;
    margin-top: 2%;
    

}

.hardskills {
    margin-left: 150px;
    width: 100%;
    flex: 1;
    flex-basis: 30%;
    display: inline !important;
    justify-content: top;
    align-items: center;
    
    padding: 0px 0px 0px 0px;
    left: -10%;

    height: 100vh !important;
    margin-top: -10vh !important;
}

.hardskill-title {

    font-weight: bold;
    font-size: 1.8vh;
    margin-bottom: 0;

}

.hardskill-description {
    display: inline-block;
    margin-left: 10px;
    color: rgb(83, 83, 83);
    font-size: 1.7vh;
}

.blank {
    display: fixed;
    top: 20%;
    width: 80%;
    flex: 2;
    flex-basis: 10%;
    float: top;
    justify-content: center;
    align-items: top;
    height: 100%;
}

.responsabilities {
    /* Positioned the responsibilities element properly */
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 80%;
    flex: 3;
    flex-basis: 50%;
    float: top;
    justify-content: center;
    align-items: top;
    height: 100vh !important;
    margin-top: 10vh !important;
}

.blurred {
    filter: blur(5px);
    /* Apply blur effect */
}
.custom-experience-list{

    margin-top:20%;
}
.custom-experience-list .ant-list-item {
    
    position: relative;

}

.custom-experience-list .ant-list-item::after {
    content: '';
    position: absolute;
    
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(to right, #db2424,#9e1800);
    /* Replace with your desired gradient colors */
}


.custom-experience-list-responsabilities{
    top: 0%;
    height: 100%;
}
.custom-experience-list-responsabilities .ant-list-item {
    
    position: relative;

}

.custom-experience-list-responsabilities .ant-list-item::after {
    content: '';
    position: absolute;
    
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(to right, #004ddd, #0066ff);
    /* Replace with your desired gradient colors */
}

.hardskills-style {

    align-items: top;
    
    font-size: 4.5vh;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    background-image: linear-gradient(to right, #db2424,#b61b00,#dd1a00);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.ant-list-item .exp-grid{
    margin-bottom: 0px !important;
    padding: 0px 0px 0px 0px;

}


.responsabilities-style {

    align-items: top;
    
    font-size: 4.5vh;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    background-image: linear-gradient(to right, #6000ddbe, #0066ff);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}


.question-main {
    margin-top:1% !important;
    margin-left: 1.5% !important;
    height: 6.2% !important;
    width: 6.2% !important;
    max-height: 6.2% !important;
    max-width: 6.2% !important;
    margin: 0px 0px 0px 0px ;
    padding: 0px 0px 0px 0px ;
    cursor: pointer;
}
