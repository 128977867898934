/* Chat.css */

/* Center the chat modal */
.chat-modal {
    width: 100%!important;
    max-width: 100%!important;
    height: 90% !important;
    max-height: 90% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -2%;
}

/* Set the chat modal width to 70% */
.chat-modal .ant-modal-content {
    height: 80% !important;
    max-height: 80% !important;
    width: 60% !important;
    max-width: 60% !important;
    /* Optional: Limit the maximum width if needed */
}
.ant-modal-body{

    height: 90% !important;
    max-height: 90% !important;
}

/* Style the chat history */
.chat-history {

    height: 99% !important;
    max-height: 100% !important;
    /* Set a maximum height and make it scrollable */
    overflow-y: auto;
}

/* Style chat messages */
.chatMessage {
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 5px;
    padding: 8px;
    border-radius: 40px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.349);
    max-width: 55%;
    min-width: 8%;
}

/* Style user messages */
.userMessage {
    background-color: #fff;
    float: right !important;
}

/* Style bot messages */
.botMessage {
    background-color: #e6f7ff;
}
.chat-content{
    margin-left: 10%;
    margin-right: 10%;
    white-space: pre-wrap;

}

.new-message{
    margin-bottom:-2% !important;
    height: 10%  !important;
}

.line{
    overflow-y: auto;
    height: auto;
    width: 100% !important;
}
