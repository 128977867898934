.projects {
    margin-top:60px !important;
    justify-content: space-around;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    width: 80% !important;
    margin-left:10%;
}

.ant-card{
    display: relative !important;
    top: 0% !important;
    height: 210px !important;
    width: 30% !important;
    justify-content: center;
    box-shadow: 1px 1px 3px rgba(15, 15, 15, 0.664) !important;
}
.social-container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top:5px;
}
.projects svg{
    height: 70% !important;
    width: 70% !important;
}